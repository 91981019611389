import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				GearGurus
			</title>
			<meta name={"description"} content={"Експертний догляд за вашою їздою"} />
			<meta property={"og:title"} content={"GearGurus"} />
			<meta property={"og:description"} content={"Експертний догляд за вашою їздою"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
		</Helmet>
		<Components.Header2 />
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Питання що часто задаються
			</Text>
			<Text margin="0px 0px 70px 0px" font="--base" color="--light" sm-margin="0px 0px 50px 0px">
				Кожне запитання є початком нової подорожі на GearGurus. Ми склали список поширених запитань, щоб роз’яснити наші послуги та ваші очікування. Ваш спокій так само важливий, як і стан вашого автомобіля.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
						Які типи транспортних засобів обслуговує GearGurus?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						GearGurus пропонує широкий вибір транспортних засобів, від сімейних седанів до високопродуктивних спортивних автомобілів, забезпечуючи найвищий рівень догляду за кожною маркою та моделлю.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
						Чи може GearGurus здійснювати технічне обслуговування залежно від виробника?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Так, наші технічні спеціалісти добре розбираються в унікальних вимогах різних виробників і пропонують обслуговування відповідно до конкретних потреб вашого автомобіля.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
						Чи потрібно записуватися на обслуговування?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Запис на прийом гарантує мінімальний час очікування та безперебійне обслуговування.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
						Як часто я повинен обслуговувати свій автомобіль?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Частота обслуговування може відрізнятися залежно від марки, моделі та звичок водіння вашого автомобіля. Як загальна вказівка, ми рекомендуємо перевірку кожні шість місяців або 5000 миль.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
						Чи надає GearGurus якусь гарантію на свою роботу?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Ми підтримуємо свою майстерність, пропонуючи гарантії на різноманітні послуги. Ваше задоволення - наша гарантія.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--headline3" color="--light">
						Що мені робити, якщо я думаю, що мій автомобіль потребує ремонту?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Прислухайтеся до своїх інстинктів і свого автомобіля. Якщо ви помітите щось незвичайне, GearGurus тут, щоб діагностувати та вирішити будь-які потенційні проблеми.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65d5d38ffdcae00021def28a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});